import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { pinia } from './store'
import Components from './utils/install-components'
import { ConfigProvider } from 'ant-design-vue'

import './assets/css/reset.css'
import './styles/common.less'
import './styles/compatible.less'
import './styles/map-common.less'

// import './mock/index.js'

createApp(App)
  .use(router)
  .use(pinia)
  .use(Components)
  .use(ConfigProvider)
  .mount('#app')
