import { App } from 'vue'
import {
  Button,
  Input,
  Checkbox,
  Form,
  message,
  Layout,
  Menu,
  Table,
  Select,
  Modal,
  Row,
  Col,
  Space,
  Divider,
  Radio,
  Switch,
  DatePicker,
  Steps,
  Image
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import Container from '@/components/common/container.vue'
import mitt from 'mitt'
import { getZIndex } from '@/utils/zIndex'

export const Mit = mitt()

export default {
  install(app: App) {
    app.component('c-container', Container)
    app.config.globalProperties.$message = message
    app.config.globalProperties.$bus = Mit
    app.config.globalProperties.$alert = (title: string, options = {}) => {
      return new Promise(resolve => {
        Modal.confirm({
          title,
          okText: '确定',
          cancelText: '取消',
          zIndex: getZIndex(),
          ...options,
          onOk() {
            resolve('confirm')
          },
          onCancel() {
            resolve('cancel')
          }
        })
      })
    }
    app
      .use(Button)
      .use(Input)
      .use(Checkbox)
      .use(Form)
      .use(Layout)
      .use(Menu)
      .use(Table)
      .use(Select)
      .use(Modal)
      .use(Row)
      .use(Col)
      .use(Space)
      .use(Divider)
      .use(Switch)
      .use(Radio)
      .use(DatePicker)
      .use(Steps)
      .use(Image)
  }
}