import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const  routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/map'
  },
  {
    name: 'Map',
    path: '/map',
    component: () => import('@/pages/map.vue')
  }
]

export default createRouter({
  history: createWebHistory(),
  routes
})