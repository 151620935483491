import { message } from 'ant-design-vue'
import { ref, Ref, computed, reactive } from 'vue'

interface PageInfo {
  pageNo: number
  pageSize: number
}

interface Props {
  pagination?: boolean
  pageSizeOptions?: string[]
  showSizeChanger?: boolean
  showQuickJumper?: boolean
  type?: string
}

interface Pagination {
  total: Ref<number>
  pageInfo: PageInfo
  pagination: any
  pageChange(page: { current: number, pageSize: number, total: number }): void
  changePageInfo(current: number, size: number): void
}

export const usePagination = function <T extends Props>(props: T): Pagination {

  const pageInfo = reactive<PageInfo>({
    pageNo: 1,
    pageSize: 10
  })

  if (props.pageSizeOptions) {
    pageInfo.pageSize = parseInt(props.pageSizeOptions[0]) || 10
  }

  const total = ref<number>(0)

  const pagination = computed(() => {
    return props.pagination && {
      showSizeChanger: props.showSizeChanger ?? true,
      showQuickJumper: props.showQuickJumper ?? true,
      pageSizeOptions: props.pageSizeOptions || ['10', '20', '50'],
      total: total.value,
      buildOptionText: ({ value }: any) => `${value}条/页`,
      showTotal: (total: string | number) => `共${total}条`,
      current: pageInfo.pageNo,
      pageSize: pageInfo.pageSize,
    }
  })

  function pageChange(page: { current: number, pageSize: number, total: number }): void {
    console.log('pageChange', page);
    if (props.type == 'es') {
      if (page.total > 10000) {
        let pageN = 0
        pageN = 10000 / page.pageSize
        if (page.current <= pageN) {
          changePageInfo(page.current, page.pageSize)
        } else {
          message.warning('最多只能查前10000条数据！')
        }
      } else {
        changePageInfo(page.current, page.pageSize)
      }
    } else {
      changePageInfo(page.current, page.pageSize)
    }
  }

  function changePageInfo(current: number, size: number): void {
    pageInfo.pageNo = current
    pageInfo.pageSize = size
    console.log('changePageInfo', current, size);
  }

  return {
    total,
    pageInfo,
    pagination,
    pageChange,
    changePageInfo
  }
}